import 'flowbite';
import './main.css';

import { createApp } from 'vue';

import { globalCookiesConfig } from "vue3-cookies";
import { createHead } from '@unhead/vue';
import axios from 'axios';
import vClickOutside from 'click-outside-vue3';
import { createPinia } from 'pinia';
import { createORM } from 'pinia-orm';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { initPosthog } from '@ui-lib';

import router from './router';
import Website from './Website.vue';

const store = createPinia().use(createORM()).use(piniaPluginPersistedstate);

const head = createHead();

axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;

// Init Cookies
globalCookiesConfig({
    expireTimes: "180d",
    path: "/",
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    secure: import.meta.env.VITE_ENV !== 'local',
    sameSite: "Lax",
});

// Init Posthog
initPosthog(import.meta.env.VITE_POSTHOG_TOKEN)

// Create the app
const app = createApp(Website)
  .use(store)
  .use(vClickOutside)
  .use(router)
  .use(head);

app.mount('#app');