<script lang="ts" setup>
  import { useHead, useSeoMeta } from '@unhead/vue';

  useHead({
    titleTemplate: '%s',
  });

  useSeoMeta({
    ogImage: '/assets/image/og/og-home.jpg',
    twitterCard: 'summary_large_image',
  });
</script>

<template>
  <RouterView />
</template>
